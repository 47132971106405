<template>
  <page-view :title="'告知说明'" left-arrow :nav-border="false" custom-class="page-sbjlcx">
    <div style="padding: 10px 0;text-indent: 2em;">
      尊敬的电力客户：欢迎您办理分布式光伏并网业务，请您仔细阅读以下内容。
    </div>
    <van-image :src="src" @click="preview"/>
  </page-view>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: "gzsm",
  data(){
    return {
      src:'',
    }
  },
  mounted() {
    if(this.$route.query.type == 1){
      this.src = require('@/assets/images/gfbw/zrrgzsm.png');
    }else{
      this.src = require('@/assets/images/gfbw/fzrrgzsm.png');
    }
  },
  methods:{
    preview(){
      ImagePreview([this.src])
    }
  }
}
</script>

<style scoped>

</style>